import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateDetail from '../dateDetail';
import TestNameDetail from '../testNameDetail';
import TestDetailCard from '../testDetailCard';
import { LabtestData } from '../../observationsJson';
import moment from 'moment';
import { appConfig } from "../../../common/constants";
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function LabData(props) {
  const [title, setTitle] = useState('')
  const [descData, setDescData] = useState({})
  let [progress, setProgress] = useState(false);
  const [data, setData] = useState([])
  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if(props.data?.patient360==="Y"){
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.ehrPatientId}&eventType=Observation&from=0&size=${props.formValues.headerData["Observation"]}&combinedFlag=Y`
      }else if (props.data.patientId != "" || null || undefined) {
        url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=Observation&from=0&size=${props.formValues.headerData["Observation"]}&combinedFlag=Y`
      } else {
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=Observation&from=0&size=${props.formValues.headerData["Observation"]}&combinedFlag=Y`
      }

    } else {
       url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=Observation&from=0&size=${props.formValues.headerData["Observation"]}&patientId=${props.data.subjectId}&combinedFlag=Y`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          var initialdata =[]
          var data = [...responseData.data.filter((item) => item.category_code === "laboratory"), ...responseData.data.filter((item) => item.category_code === "Laboratory")]
          // var vitalsignsdata=[]
          //finding only labdata
          data.map((item, i) => {
        
              var obj = {
                "testTitle": item.Name,
                "value": item?.value_quantity ? (item?.value_quantity[0]?.value).toFixed(2) : "",
                "ref":item?.value_quantity ? item?.Name + ' ' + item?.value_quantity[0]?.unit : "",
                "unit": item?.value_quantity ? item?.value_quantity[0]?.unit : "",
                "date": item?.date_of_observation,
                "valueString":item?.result_text && item?.result_text 
              }
              initialdata.push(obj)
            // } else if (props.app === "Patient" && item?.category[0]?.coding[0].code === "vital-signs" || props.app === "Site"  && item?.category?.coding[0].code === "vital-signs" ) {
            //   var obj = {
            //     "testTitle": item?.code?.text,
            //     "value": item?.valueQuantity?.value != undefined ? (item?.valueQuantity?.value).toFixed(2) : "",
            //     "ref": item?.code?.text + ' ' + item?.valueQuantity?.unit,
            //     "unit": item?.valueQuantity?.unit,
            //     "date": moment(item?.effectiveDateTime).format('Do MMM YYYY')
            //   }
            //   vitalsignsdata.push(obj)
            
          })
          console.log(initialdata, LabtestData, "labdata")
          //adding min and max
          const mergedData = (a1, a2) =>
            a1.map(itm => ({
              ...a2.find((item) => (item.testTitle === itm.testTitle && item.unit === itm.unit) && item),
              ...itm
            }));
          var mappedData = mergedData(initialdata, LabtestData)
          //           date: "17th Jul 2015"
          // max: 16.1
          // min: 11.8
          // testTitle: "Platelet distribution width [Entitic volume] in Blood by Automated count"
          // unit: "fL"
          // value: "309.77
          console.log(mappedData, "merlabdata");
          let sortedArray = mappedData.sort((a, b) => new Date(a.date) - new Date(b.date))

          let formatArray = sortedArray.map((item, i) => { return { ...item, date: moment(item.date).format('Do MMM YYYY') } })
          const datawithOutDup = Array.from(new Set(formatArray.map(s => s.ref)))
            .map(labname => {
              return {
                ...formatArray.find((item) => (item.ref === labname) && item),
                ref: labname,
                value: formatArray.filter(s => s.ref === labname).map(edition => edition.value),
                date: formatArray.filter(s => s.ref === labname).map(edition => edition.date),

              }
            })
          console.log(datawithOutDup, "datawithOutDup")
          // setData(mappedData)
          //create arr in b/w min and max for graph
          // var limit=8
          // var min = 1.005;
          // var max = 1.3;
          // var diff = (max - min) / limit;
          // var arr = [];
          // arr.push(min);
          // for (var i = 0; i < limit; i++) {
          //   arr[i+1]=(arr[i] + diff);
          // }
          // console.log(arr);
          /////////////////////////////////////////
          const getData = (linedata, min, max) => {
            console.log(linedata, min, max)
            var newdata = linedata.map((item, i) => {
              if ((max != "" && item > max) || (min != "" && item < min)) {
                var val = {
                  y: parseFloat(item),
                  color: '#FF0000'
                }
                return val
              } else return parseFloat(item)
            })
            return newdata
          }
          var finaldata = datawithOutDup.map(v => ({ ...v, data: getData(v.value, v.min, v.max), desc: "", xaxis: v.date }))
          console.log(finaldata, "final")
          // var finaldata = [
          //   { "testTitle": "HB1AC", "date": "", "desc": "", data: getData([5.6, 6.6, 7, 6.3, 5.8], 3.9, 6.2), xaxis: xaxis, min: 3.9, max: 6.2, units: "percentage" },
          //   { "testTitle": "Glucose Tolerance Test", "date": "", "desc": "", data: getData([200, 250, 270, 210, 190], 139, 199), xaxis: xaxis, min: 139, max: 199, units: "mg/dl" },
          //   { "testTitle": "LDL", "date": "", "desc": "", data: getData([137, 167, 173, 162, 158], 99, 159), xaxis: xaxis, min: 99, max: 159, units: "mg/dl" },
          // ]
          setData(finaldata)
        }
        else {
        }
      })

  }, [])

  const getScales = (minRange, maxRange, min, max) => {
    var scales = [],                  // Prepare some variables
      ranges = maxRange + 1 - minRange,   // Amount of elements to be returned.
      range = (max - min) / ranges;        // Difference between min and max
    for (var i = 0; i < ranges; i++) {
      scales.push({
        range: i + minRange,        // Current range number
        min: min + range * i,
        max: min + range * (i + 1)
      });
    }
    return scales;
  }

  const clickHandler = (e, index, title) => {
    console.log(data[index],title)
    e.preventDefault();
    setDescData(data[index])
    setTitle(title)
  }
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/labdata/add')
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ?
          <>
            <section>
              <MHSearchField
                title="Lab Reports"
                label="Search Lab Report"
                addBtnText="ADD LAB REPORT"
                // addBtnText="Add Medication"
                app={props.app}
                handleChange={handleChange}
              />
            </section>
            <section className="labDetailPage">
              <section className="labTableRow">
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <section className="row">
                      {/* <div className="col-12 col-sm-4">
                        <span><b>Date</b></span>
                      </div> */}
                      <div className="col-12 col-sm-8">
                        <span><b>Test Name</b></span>
                      </div>
                    </section>
                  </div>
                  <div className="col-sm-6 col-md-8">
                    <span><b>Detail</b></span>
                  </div>
                </div>
              </section>
              <section className="">
                <div className="row">

                  <div className="col-sm-6 col-md-4 datedescription">
                    {data.map((item, i) => (
                      <section className="row mb-3">
                        {/* <div className="col-12 col-sm-4">
                          <DateDetail
                            year={new Date(item.date).getFullYear()}
                            mdate={month_names_short[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate()}
                            icon={'fas fa-flask'}
                            icolor='#863DD0'
                          />
                        </div> */}
                        <div className="col-12 col-md-12 col-sm-8">
                          <TestNameDetail
                            title={item.testTitle}
                            clickHandler={(e) => clickHandler(e, i, item.testTitle)}
                            color="#863DD0"
                          />
                        </div>
                      </section>
                    ))}
                  </div>

                  <div className="col-sm-6 col-md-8">
                    <TestDetailCard
                      // data={descData}
                      // title={title}
                      data={Object.keys(descData).length > 0 ? descData : data[0]}
                      title={data.length > 0 && title == "" ? data[0].testTitle : title}
                    />
                  </div>
                </div>
              </section>
            </section>
          </> : "No data found"
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}


export default withRouter(connect(mapStateToProps, null)(LabData));



