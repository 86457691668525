import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from './slider';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment'

function Immunization(props) {
  var lilist = props.data || []
  let [progress, setProgress] = useState(false);
  const navigateTo = () => {
    if (props.app !== "Patient") {
      props.history.push('/site/patient/medicalhistory/immunizations')
    } else {
      props.history.push('/patient/medicalhistory/immunizations')
    }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        lilist.length > 0 ?

          <section>
            <section className="row problemDetail">
              <div className="col-12 d-flex justify-content-between" style={{ cursor: "pointer" }} onClick={navigateTo}>
                <h6>Immunizations</h6>
                {/* <span><i className="fas fa-ellipsis-h"></i></span> */}
              </div>
              <div className="immunization col-12">
                <Slider id="immunizationSlider" data={lilist}>
                  {
                    lilist.map((item, inx) => {
                      return <li data_key={`slider_${inx}`} key={`sl+${inx}`} onClick={navigateTo} style={{ cursor: "pointer" }}>
                        <div className="daterow d-flex">
                          <aside className="date">{moment(item.date_of_immunization).format('DD')}</aside>
                          <aside className="d-flex justify-content-center flex-column month">
                            <span>{moment(item.date_of_immunization).format('MM')}</span>
                            <span>{moment(item.date_of_immunization).format('YYYY')}</span>
                          </aside>
                        </div>
                        <div className="centerline">
                          <p></p>
                          <span><i className="far fa-dot-circle"></i></span>
                        </div>
                        <div className="bottomText">
                          <section className="d-flex mb-1 align-items-center">
                            <i className="fas fa-syringe"></i>
                            <span>{item.Name}</span>
                          </section>
                          <div>
                            {item.Name}
                          </div>
                        </div>
                      </li>
                    })
                  }
                </Slider>
              </div>
            </section>
          </section> : <section className="problemDetail ">
            <h6 >Immunization</h6>
            {/* <span><i className="fas fa-ellipsis-h"></i></span> */}
            <p>No Data Found</p>
          </section>
      }
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Immunization));


