import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import DiseaseTopBar from '../diseaseTopBar';
import Slider from '../slider';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MC from '../../../img/MC.png'
import moment from 'moment';
import { appConfig } from "../../../common/constants";
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const MedicalCondition = (props) => {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  const [disCard, setDisCard] = useState({});
  const [sliderFlag, setSliderFlag] = useState(true);
  const [activeCount, setActiveCount] = useState("");
  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if(props.data?.patient360==="Y"){
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.ehrPatientId}&eventType=Condition&from=0&size=${props.formValues.headerData["Condition"]}&combinedFlag=Y`
      }else if (props.data.patientId != "" || null || undefined) {
        url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=Condition&from=0&size=${props.formValues.headerData["Condition"]}&combinedFlag=Y`
      } else {
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=Condition&from=0&size=${props.formValues.headerData["Condition"]}&combinedFlag=Y`
      }

    } else {
      url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&&eventType=Condition&from=0&size=${props.formValues.headerData["Condition"]}&patientId=${props.data.subjectId}&combinedFlag=Y`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        //console.log(responseData, "diseases")
        setProgress(false);
        if (responseData.data.length > 0) {
          var apiData = responseData
          var data = []
          var objActive = []
          var objRest = []
          apiData.data.map((item, i) => {
            var obj = {
              // name: item?.substance?.text,
              // startDate: item?.onset,
              // status: item?.status
              name: item?.Condition,
              status: item?.Status,
              startDate: item?.date_of_diagnosis
            }
            if (obj.status === "active") {
              objActive.push(obj)
            } else {
              objRest.push(obj)
            }
            data = [...objActive, ...objRest]
          })
          setData(data)
          setActiveCount(objActive.length)
        } else {
          //console.log('no data found')
        }
      })
  }, [])


  const liClickHandler = (item) => {
    setSliderFlag(false)
    setDisCard(item);
  }
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/disease/add')
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {data.length > 0 ?
        <>
          <section className="row">
            <div className="col-12">
              <section>
                <DiseaseTopBar
                  title="Medical Conditions"
                  icon={MC}
                  icolor="#5787BF"
                  total={data.length}
                  des1={data.length > 1 ? "Total Conditions" : "Condition"}
                  text1="Active"
                  color1=""
                  num1={activeCount}
                  text2="Resolved"
                  color2="#fd9437"
                  num2={data.length - activeCount}
                  addBtnText="Add Disease/Condition"
                  handleChange={handleChange}
                  app={props.app}
                // text3="In Treatment"
                // num3="0"
                />
              </section>
            </div>
          </section>


          <section style={{ overflow: 'hidden' }}>
            <section className="row">
              <div className="col-12">
                <div className="immunization diseaseDetail">
                  <Slider id="diseaseSlider" data={data}>
                    {
                      data.map((item, inx) => {
                        return <li data_key={`slider_${inx}`} key={`sl+${inx}`}
                          onClick={() => { liClickHandler(item) }}
                        >
                          {console.log(item, "Dddddd")}
                          <div className="dateDetail">
                            <span className="year">
                              {new Date(item.startDate).getFullYear()}
                            </span>
                            <span className="month">
                              {month_names_short[new Date(item.startDate).getMonth()]}
                            </span>
                            <span className="date">
                              {new Date(item.startDate).getDate()}
                            </span>
                          </div>
                          <section className="diseasCard">
                            <div>
                              <span className="roundedIcon">
                                <i className="fas fa-bed" style={{ color: '#5787BF' }}></i>
                              </span>
                              <span>
                                <i className="fas fa-circle mr-1" style={{ color: item.status === "active" ? '#5EED6F' : "#fd9437" }}></i>
                              </span>
                            </div>
                            <p>{item.name}</p>
                          </section>

                        </li>
                      })
                    }
                  </Slider>
                </div>
              </div>

            </section>
          </section>

          <section className="row">
            <div className="col-5">
              <DiseaseDetail detail={Object.keys(disCard).length != 0 && disCard.constructor === Object ? disCard : data[0]} />
            </div>
          </section>
        </> : "No Medical Conditions Found"
      }
    </>
  )

}


function DiseaseDetail(props) {
  const data = props.detail || {};
  return (
    <section className="mediCard vaccineCard">
      <div className="top d-flex justify-content-between mb-3">
        <div className="flex-grow-1 d-flex align-items-center">
          <span>
            <i className="fas fa-circle ml-1" style={{ color: data.status === "active" ? '#5EED6F' : "#fd9437" }}></i>
          </span> <span className="ml-2">{data.name}</span>

        </div>
      </div>
      <div className="daterow d-flex justify-content-between">
        <div className="dates d-flex">
          <span className="label">
            Start Date
          </span>
          <span className="ml-3">
            {month_names_short[new Date(data.startDate).getMonth()] + ' ' + new Date(data.startDate).getDate() + ' ' + new Date(data.startDate).getFullYear()}
          </span>
        </div>
        <div className="dates d-flex">
          <span className="label">
            Clinical Status
          </span>
          <span className="ml-3">
            {data.status}
          </span>
        </div>
      </div>
      <div className="daterow d-flex justify-content-between">
        <div className="dates d-flex">
          <span className="label">
            End Date
          </span>
          {/* <span className="ml-3">
            {data.ed}
          </span> */}
        </div>
      </div>
    </section>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}


export default withRouter(connect(mapStateToProps, null)(MedicalCondition));






