import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import Medicard from '../mediCard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { appConfig } from "../../../common/constants";
function Medications(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if(props.data?.patient360==="Y"){
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.ehrPatientId}&eventType=MedicationOrder&from=0&size=${props.formValues.headerData["MedicationOrder"]}&combinedFlag=Y`
      }else if (props.data.patientId != "" || null || undefined) {
        url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=MedicationOrder&from=0&size=${props.formValues.headerData["MedicationOrder"]}&combinedFlag=Y`
      } else {
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=MedicationOrder&from=0&size=${props.formValues.headerData["MedicationOrder"]}&combinedFlag=Y`
      }

    } else {
      url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&&eventType=MedicationOrder&from=0&size=${props.formValues.headerData["MedicationOrder"]}&patientId=${props.data.subjectId}&combinedFlag=Y`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          //console.log(responseData, "medications")
          var finaldata = []
          var objActive = []
          var objRest = []
          responseData.data.map((item, i) => {
            var obj = {
              // "instruction": item.dosageInstruction[0].doseQuantity.value + '/' + item.dosageInstruction[0].timing.repeat.periodUnits,
              "instruction": "",
              "startDate": moment(item?.onset).format('MMM Do YYYY'),
              "endDate": "",
              "title": item?.name,
              "status": item?.status
            }
            if (obj.status === "active") {
              objActive.push(obj)
            } else {
              objRest.push(obj)
            }
            finaldata = [...objActive, ...objRest]
          })
          setData(finaldata)
        } else {
          //console.log('no data found')
        }
      })
  }, [])
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/medications/add')
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ? <>
          <section>
            <MHSearchField
              title="Medications"
              label="Search Medication"
              btnName="ADD MEDICINE"
              addBtnText="Add Medication"
              app={props.app}
              handleChange={handleChange}
            />
          </section>

          <section className="row">
            {data.map((item, i) => (
              <div className="col-12 col-sm-4">
                <Medicard
                  instruction={item.instruction}
                  title={item.title}
                  sd={item.startDate}
                  ed={item.endDate}
                  status={item.status}
                />
              </div>
            ))}

          </section>
        </>
          : "No medications found"
      }
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Medications));



